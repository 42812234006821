/* shop/BasketAndOrder.svelte generated by Svelte v3.38.3 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	noop,
	run_all,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "svelte/internal";

import Basket from "./components/Basket.svelte";
import Order from "./components/Order.svelte";
import Summary from "./components/Summary.svelte";
import ThankYou from "./components/ThankYou.svelte";
import basketStore, { basketItemsCount } from "./basketStore";
import orderStore, { isOrderReadyForSubmit } from "./orderStore";
import submitOrder from "./submitOrder";

function create_if_block_11(ctx) {
	let basket;
	let current;
	basket = new Basket({});

	return {
		c() {
			create_component(basket.$$.fragment);
		},
		m(target, anchor) {
			mount_component(basket, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(basket.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(basket.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(basket, detaching);
		}
	};
}

// (84:0) {#if display_state == DISPLAY_STATES.ORDER}
function create_if_block_10(ctx) {
	let order_1;
	let current;

	order_1 = new Order({
			props: {
				delivery: /*delivery*/ ctx[5],
				paymentMethods: /*paymentMethods*/ ctx[6]
			}
		});

	return {
		c() {
			create_component(order_1.$$.fragment);
		},
		m(target, anchor) {
			mount_component(order_1, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(order_1.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(order_1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(order_1, detaching);
		}
	};
}

// (88:0) {#if display_state == DISPLAY_STATES.SUMMARY}
function create_if_block_9(ctx) {
	let summary;
	let current;
	summary = new Summary({});

	return {
		c() {
			create_component(summary.$$.fragment);
		},
		m(target, anchor) {
			mount_component(summary, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(summary.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(summary.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(summary, detaching);
		}
	};
}

// (92:0) {#if display_state == DISPLAY_STATES.THANK_YOU}
function create_if_block_8(ctx) {
	let thankyou;
	let current;
	thankyou = new ThankYou({ props: { order: /*order*/ ctx[2] } });

	return {
		c() {
			create_component(thankyou.$$.fragment);
		},
		m(target, anchor) {
			mount_component(thankyou, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const thankyou_changes = {};
			if (dirty & /*order*/ 4) thankyou_changes.order = /*order*/ ctx[2];
			thankyou.$set(thankyou_changes);
		},
		i(local) {
			if (current) return;
			transition_in(thankyou.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(thankyou.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(thankyou, detaching);
		}
	};
}

// (100:4) {#if display_state == DISPLAY_STATES.BASKET}
function create_if_block_6(ctx) {
	let if_block_anchor;
	let if_block = /*$basketItemsCount*/ ctx[3] > 0 && create_if_block_7(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(ctx, dirty) {
			if (/*$basketItemsCount*/ ctx[3] > 0) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_7(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

// (102:8) {#if $basketItemsCount > 0}
function create_if_block_7(ctx) {
	let button;
	let mounted;
	let dispose;

	return {
		c() {
			button = element("button");
			button.textContent = "Pokračovat";
			attr(button, "class", "basketButton");
			attr(button, "type", "button");
		},
		m(target, anchor) {
			insert(target, button, anchor);

			if (!mounted) {
				dispose = listen(button, "click", /*click_handler*/ ctx[9]);
				mounted = true;
			}
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(button);
			mounted = false;
			dispose();
		}
	};
}

// (111:4) {#if display_state == DISPLAY_STATES.ORDER}
function create_if_block_3(ctx) {
	let if_block_anchor;
	let if_block = /*$basketItemsCount*/ ctx[3] > 0 && create_if_block_4(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(ctx, dirty) {
			if (/*$basketItemsCount*/ ctx[3] > 0) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_4(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

// (112:8) {#if $basketItemsCount > 0}
function create_if_block_4(ctx) {
	let button;
	let t1;
	let if_block_anchor;
	let mounted;
	let dispose;
	let if_block = /*$isOrderReadyForSubmit*/ ctx[4] && create_if_block_5(ctx);

	return {
		c() {
			button = element("button");
			button.textContent = "Zpět";
			t1 = space();
			if (if_block) if_block.c();
			if_block_anchor = empty();
			attr(button, "class", "basketButton basketButton__rev");
			attr(button, "type", "button");
		},
		m(target, anchor) {
			insert(target, button, anchor);
			insert(target, t1, anchor);
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);

			if (!mounted) {
				dispose = listen(button, "click", /*click_handler_1*/ ctx[10]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (/*$isOrderReadyForSubmit*/ ctx[4]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_5(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d(detaching) {
			if (detaching) detach(button);
			if (detaching) detach(t1);
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
			mounted = false;
			dispose();
		}
	};
}

// (119:12) {#if $isOrderReadyForSubmit}
function create_if_block_5(ctx) {
	let button;
	let mounted;
	let dispose;

	return {
		c() {
			button = element("button");
			button.textContent = "Pokračovat";
			attr(button, "class", "basketButton");
			attr(button, "type", "button");
		},
		m(target, anchor) {
			insert(target, button, anchor);

			if (!mounted) {
				dispose = listen(button, "click", /*click_handler_2*/ ctx[11]);
				mounted = true;
			}
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(button);
			mounted = false;
			dispose();
		}
	};
}

// (129:4) {#if display_state == DISPLAY_STATES.SUMMARY}
function create_if_block_1(ctx) {
	let if_block_anchor;
	let if_block = /*$basketItemsCount*/ ctx[3] > 0 && /*$isOrderReadyForSubmit*/ ctx[4] && create_if_block_2(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(ctx, dirty) {
			if (/*$basketItemsCount*/ ctx[3] > 0 && /*$isOrderReadyForSubmit*/ ctx[4]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_2(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

// (130:8) {#if $basketItemsCount > 0 && $isOrderReadyForSubmit}
function create_if_block_2(ctx) {
	let button0;
	let t1;
	let button1;
	let mounted;
	let dispose;

	return {
		c() {
			button0 = element("button");
			button0.textContent = "Zpět";
			t1 = space();
			button1 = element("button");
			button1.textContent = "Závazně objednat";
			attr(button0, "class", "basketButton basketButton__rev");
			attr(button0, "type", "button");
			attr(button1, "class", "basketButton");
			attr(button1, "type", "button");
		},
		m(target, anchor) {
			insert(target, button0, anchor);
			insert(target, t1, anchor);
			insert(target, button1, anchor);

			if (!mounted) {
				dispose = [
					listen(button0, "click", /*click_handler_3*/ ctx[12]),
					listen(button1, "click", /*click_handler_4*/ ctx[13])
				];

				mounted = true;
			}
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(button0);
			if (detaching) detach(t1);
			if (detaching) detach(button1);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (147:0) {#if showWait}
function create_if_block(ctx) {
	let div;

	return {
		c() {
			div = element("div");
			div.textContent = "přijímáme objednávku...";
			attr(div, "class", "wait svelte-9fao4y");
		},
		m(target, anchor) {
			insert(target, div, anchor);
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

function create_fragment(ctx) {
	let div1;
	let t0;
	let t1;
	let t2;
	let t3;
	let div0;
	let t4;
	let t5;
	let t6;
	let current;
	let if_block0 = /*display_state*/ ctx[0] == /*DISPLAY_STATES*/ ctx[7].BASKET && create_if_block_11(ctx);
	let if_block1 = /*display_state*/ ctx[0] == /*DISPLAY_STATES*/ ctx[7].ORDER && create_if_block_10(ctx);
	let if_block2 = /*display_state*/ ctx[0] == /*DISPLAY_STATES*/ ctx[7].SUMMARY && create_if_block_9(ctx);
	let if_block3 = /*display_state*/ ctx[0] == /*DISPLAY_STATES*/ ctx[7].THANK_YOU && create_if_block_8(ctx);
	let if_block4 = /*display_state*/ ctx[0] == /*DISPLAY_STATES*/ ctx[7].BASKET && create_if_block_6(ctx);
	let if_block5 = /*display_state*/ ctx[0] == /*DISPLAY_STATES*/ ctx[7].ORDER && create_if_block_3(ctx);
	let if_block6 = /*display_state*/ ctx[0] == /*DISPLAY_STATES*/ ctx[7].SUMMARY && create_if_block_1(ctx);
	let if_block7 = /*showWait*/ ctx[1] && create_if_block(ctx);

	return {
		c() {
			div1 = element("div");
			if (if_block0) if_block0.c();
			t0 = space();
			if (if_block1) if_block1.c();
			t1 = space();
			if (if_block2) if_block2.c();
			t2 = space();
			if (if_block3) if_block3.c();
			t3 = space();
			div0 = element("div");
			if (if_block4) if_block4.c();
			t4 = space();
			if (if_block5) if_block5.c();
			t5 = space();
			if (if_block6) if_block6.c();
			t6 = space();
			if (if_block7) if_block7.c();
			attr(div0, "class", "basketButtonWrap");
			attr(div1, "class", "container basket");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			if (if_block0) if_block0.m(div1, null);
			append(div1, t0);
			if (if_block1) if_block1.m(div1, null);
			append(div1, t1);
			if (if_block2) if_block2.m(div1, null);
			append(div1, t2);
			if (if_block3) if_block3.m(div1, null);
			append(div1, t3);
			append(div1, div0);
			if (if_block4) if_block4.m(div0, null);
			append(div0, t4);
			if (if_block5) if_block5.m(div0, null);
			append(div0, t5);
			if (if_block6) if_block6.m(div0, null);
			append(div1, t6);
			if (if_block7) if_block7.m(div1, null);
			current = true;
		},
		p(ctx, [dirty]) {
			if (/*display_state*/ ctx[0] == /*DISPLAY_STATES*/ ctx[7].BASKET) {
				if (if_block0) {
					if (dirty & /*display_state*/ 1) {
						transition_in(if_block0, 1);
					}
				} else {
					if_block0 = create_if_block_11(ctx);
					if_block0.c();
					transition_in(if_block0, 1);
					if_block0.m(div1, t0);
				}
			} else if (if_block0) {
				group_outros();

				transition_out(if_block0, 1, 1, () => {
					if_block0 = null;
				});

				check_outros();
			}

			if (/*display_state*/ ctx[0] == /*DISPLAY_STATES*/ ctx[7].ORDER) {
				if (if_block1) {
					if_block1.p(ctx, dirty);

					if (dirty & /*display_state*/ 1) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block_10(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(div1, t1);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}

			if (/*display_state*/ ctx[0] == /*DISPLAY_STATES*/ ctx[7].SUMMARY) {
				if (if_block2) {
					if (dirty & /*display_state*/ 1) {
						transition_in(if_block2, 1);
					}
				} else {
					if_block2 = create_if_block_9(ctx);
					if_block2.c();
					transition_in(if_block2, 1);
					if_block2.m(div1, t2);
				}
			} else if (if_block2) {
				group_outros();

				transition_out(if_block2, 1, 1, () => {
					if_block2 = null;
				});

				check_outros();
			}

			if (/*display_state*/ ctx[0] == /*DISPLAY_STATES*/ ctx[7].THANK_YOU) {
				if (if_block3) {
					if_block3.p(ctx, dirty);

					if (dirty & /*display_state*/ 1) {
						transition_in(if_block3, 1);
					}
				} else {
					if_block3 = create_if_block_8(ctx);
					if_block3.c();
					transition_in(if_block3, 1);
					if_block3.m(div1, t3);
				}
			} else if (if_block3) {
				group_outros();

				transition_out(if_block3, 1, 1, () => {
					if_block3 = null;
				});

				check_outros();
			}

			if (/*display_state*/ ctx[0] == /*DISPLAY_STATES*/ ctx[7].BASKET) {
				if (if_block4) {
					if_block4.p(ctx, dirty);
				} else {
					if_block4 = create_if_block_6(ctx);
					if_block4.c();
					if_block4.m(div0, t4);
				}
			} else if (if_block4) {
				if_block4.d(1);
				if_block4 = null;
			}

			if (/*display_state*/ ctx[0] == /*DISPLAY_STATES*/ ctx[7].ORDER) {
				if (if_block5) {
					if_block5.p(ctx, dirty);
				} else {
					if_block5 = create_if_block_3(ctx);
					if_block5.c();
					if_block5.m(div0, t5);
				}
			} else if (if_block5) {
				if_block5.d(1);
				if_block5 = null;
			}

			if (/*display_state*/ ctx[0] == /*DISPLAY_STATES*/ ctx[7].SUMMARY) {
				if (if_block6) {
					if_block6.p(ctx, dirty);
				} else {
					if_block6 = create_if_block_1(ctx);
					if_block6.c();
					if_block6.m(div0, null);
				}
			} else if (if_block6) {
				if_block6.d(1);
				if_block6 = null;
			}

			if (/*showWait*/ ctx[1]) {
				if (if_block7) {
					
				} else {
					if_block7 = create_if_block(ctx);
					if_block7.c();
					if_block7.m(div1, null);
				}
			} else if (if_block7) {
				if_block7.d(1);
				if_block7 = null;
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block0);
			transition_in(if_block1);
			transition_in(if_block2);
			transition_in(if_block3);
			current = true;
		},
		o(local) {
			transition_out(if_block0);
			transition_out(if_block1);
			transition_out(if_block2);
			transition_out(if_block3);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			if (if_block2) if_block2.d();
			if (if_block3) if_block3.d();
			if (if_block4) if_block4.d();
			if (if_block5) if_block5.d();
			if (if_block6) if_block6.d();
			if (if_block7) if_block7.d();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $orderStore;
	let $basketStore;
	let $basketItemsCount;
	let $isOrderReadyForSubmit;
	component_subscribe($$self, orderStore, $$value => $$invalidate(14, $orderStore = $$value));
	component_subscribe($$self, basketStore, $$value => $$invalidate(15, $basketStore = $$value));
	component_subscribe($$self, basketItemsCount, $$value => $$invalidate(3, $basketItemsCount = $$value));
	component_subscribe($$self, isOrderReadyForSubmit, $$value => $$invalidate(4, $isOrderReadyForSubmit = $$value));
	const dataElm = document.getElementById("basketAndOrder");
	const delivery = JSON.parse(dataElm.dataset.delivery);
	const paymentMethods = JSON.parse(dataElm.dataset.paymentMethods);

	// stav zobrazeni
	const DISPLAY_STATES = {
		BASKET: "BASKET",
		ORDER: "ORDER",
		SUMMARY: "SUMMARY",
		THANK_YOU: "THANK_YOU"
	};

	let display_state = DISPLAY_STATES.BASKET;

	// zobrazeni "wait" divu
	let showWait = false;

	// objekt objednavky (vraceny po ulozeni)
	let order = null;

	// odeslani objednavky
	const handleSubmitOrder = () => {
		// odeslat objednavku do API
		// zobrazeni wait
		$$invalidate(1, showWait = true);

		// sestaveni dat
		const data = {
			...$orderStore,
			items: $basketStore.items
		};

		// odeslani
		submitOrder(
			data,
			// success callback
			xhr => {
				basketStore.clean();
				$$invalidate(2, order = JSON.parse(xhr.responseText));
				$$invalidate(0, display_state = DISPLAY_STATES.THANK_YOU);
				$$invalidate(1, showWait = false);
			},
			// error callback
			xhr => {
				alert("Je nám líto, ale nepodařilo se přijmout objednávku.");
				$$invalidate(1, showWait = false);
			}
		);
	};

	const click_handler = () => $$invalidate(0, display_state = DISPLAY_STATES.ORDER);
	const click_handler_1 = () => $$invalidate(0, display_state = DISPLAY_STATES.BASKET);
	const click_handler_2 = () => $$invalidate(0, display_state = DISPLAY_STATES.SUMMARY);
	const click_handler_3 = () => $$invalidate(0, display_state = DISPLAY_STATES.ORDER);
	const click_handler_4 = () => handleSubmitOrder();

	return [
		display_state,
		showWait,
		order,
		$basketItemsCount,
		$isOrderReadyForSubmit,
		delivery,
		paymentMethods,
		DISPLAY_STATES,
		handleSubmitOrder,
		click_handler,
		click_handler_1,
		click_handler_2,
		click_handler_3,
		click_handler_4
	];
}

class BasketAndOrder extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default BasketAndOrder;