import { writable, get } from "svelte/store";
import { derived } from 'svelte/store';

const storageKey = "___basket_kher___";

const emptyData = { items: [] };

const loadFromStorage = () => {
    let json = localStorage.getItem(storageKey);
    let data = json ? JSON.parse(json) : emptyData;
    return data;
};

const saveToStorage = (data) => {
    localStorage.setItem(storageKey, JSON.stringify(data));
};

/* store - BEGIN */
const createStore = () => {
    const store = writable(loadFromStorage());

    // "VEREJNE" metody - BEGIN
    const insertProduct = (productData, quantity) => {
        const item = createItem(productData, quantity);
        store.update(current => {
            current.items.push(item);
            return {...current};
        });
        persist();
        alert("Položka byla přidána do košíku");
    };

    const removeProduct = (book, book_type) => {
        store.update(current => {
            current.items = current.items.filter(
                item => !(item.book == book && item.book_type == book_type));
            return current;
        });
        persist();
    };

    const setProductQuantity = (book, book_type, quantity) => {
        store.update(current => {
            current.items = current.items.map(item => {
                if (item.book == book && item.book_type == book_type) {
                    item.quantity = quantity;
                    item = setFullPrice(item);
                }
                return item;
            });
            return current;
        });
        persist();
    };

    const clean = () => {
        store.update(current => { return emptyData });
        persist();
    };
    // "VEREJNE" metody - END

    // "NEVEREJNE" metody - BEGIN
    const createItem = (productData, quantity) => {
        let item = {
            book: productData.book,
            book_type: productData.book_type,
            title: productData.title,
            description: productData.description,
            unit_price: productData.unit_price,
            quantity: quantity,
        };
        item = setFullPrice(item);
        return item;
    };

    const setFullPrice = (item) => {
        item.full_price = item.quantity * item.unit_price;
        return item;
    };

    const persist = () => {
        saveToStorage(get(store));
    };
    // "NEVEREJNE" metody - END

    // vraceni vytvoreneho store
    return {
        subscribe: store.subscribe,
        unsubscribe: store.unsubscribe,
        insertProduct,
        removeProduct,
        setProductQuantity,
        clean,
    };

};
/* store - END */

// export store
const basketStore = createStore();
export default basketStore;


// basketPrice
export const basketPrice = derived(basketStore, $basketStore => {
    let price = 0;
    $basketStore.items.forEach(item => price += item.full_price);
    return price;
});


// basketItemsCount
export const basketItemsCount = derived(basketStore, $basketStore => {
    return $basketStore.items.length;
});
