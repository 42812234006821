/* shop/components/Summary.svelte generated by Svelte v3.38.3 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	destroy_each,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_data,
	space,
	text
} from "svelte/internal";

import basketStore, { basketItemsCount, basketPrice } from "./../basketStore";
import orderStore from "./../orderStore";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[4] = list[i];
	return child_ctx;
}

// (16:0) {#if $basketItemsCount > 0}
function create_if_block_7(ctx) {
	let div;
	let table;
	let thead;
	let t9;
	let tbody;
	let t10;
	let tfoot;
	let tr1;
	let td0;
	let t11;
	let t12_value = /*$orderStore*/ ctx[2].delivery_title + "";
	let t12;
	let t13;
	let t14;
	let td1;
	let t15;
	let td2;
	let t16_value = /*$orderStore*/ ctx[2].price_delivery.toLocaleString() + "";
	let t16;
	let t17;
	let t18;
	let td3;
	let t19;
	let td4;
	let t20_value = /*$orderStore*/ ctx[2].price_delivery.toLocaleString() + "";
	let t20;
	let t21;
	let t22;
	let tr2;
	let td5;
	let t23;
	let t24_value = /*$orderStore*/ ctx[2].payment_title + "";
	let t24;
	let t25;
	let td6;
	let t26;
	let td7;
	let t27_value = /*$orderStore*/ ctx[2].price_payment.toLocaleString() + "";
	let t27;
	let t28;
	let t29;
	let td8;
	let t30;
	let td9;
	let t31_value = /*$orderStore*/ ctx[2].price_payment.toLocaleString() + "";
	let t31;
	let t32;
	let t33;
	let tr3;
	let td10;
	let t35;
	let td11;
	let t36;
	let td12;
	let t37;
	let td13;
	let t38;
	let td14;
	let t39_value = (/*$basketPrice*/ ctx[3] + /*$orderStore*/ ctx[2].price_delivery + /*$orderStore*/ ctx[2].price_payment).toLocaleString() + "";
	let t39;
	let t40;
	let each_value = /*$basketStore*/ ctx[1].items;
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	let if_block = /*$orderStore*/ ctx[2].delivery_data?.subtitleHTML && create_if_block_8(ctx);

	return {
		c() {
			div = element("div");
			table = element("table");
			thead = element("thead");

			thead.innerHTML = `<tr><th>Položka</th> 
                <th>Typ</th> 
                <th>Cena</th> 
                <th>Počet</th> 
                <th>Cena celkem</th></tr>`;

			t9 = space();
			tbody = element("tbody");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t10 = space();
			tfoot = element("tfoot");
			tr1 = element("tr");
			td0 = element("td");
			t11 = text("Doprava: ");
			t12 = text(t12_value);
			t13 = space();
			if (if_block) if_block.c();
			t14 = space();
			td1 = element("td");
			t15 = space();
			td2 = element("td");
			t16 = text(t16_value);
			t17 = text(" Kč");
			t18 = space();
			td3 = element("td");
			t19 = space();
			td4 = element("td");
			t20 = text(t20_value);
			t21 = text(" Kč");
			t22 = space();
			tr2 = element("tr");
			td5 = element("td");
			t23 = text("Platba: ");
			t24 = text(t24_value);
			t25 = space();
			td6 = element("td");
			t26 = space();
			td7 = element("td");
			t27 = text(t27_value);
			t28 = text(" Kč");
			t29 = space();
			td8 = element("td");
			t30 = space();
			td9 = element("td");
			t31 = text(t31_value);
			t32 = text(" Kč");
			t33 = space();
			tr3 = element("tr");
			td10 = element("td");
			td10.textContent = "Celkem";
			t35 = space();
			td11 = element("td");
			t36 = space();
			td12 = element("td");
			t37 = space();
			td13 = element("td");
			t38 = space();
			td14 = element("td");
			t39 = text(t39_value);
			t40 = text(" Kč");
			attr(table, "class", "uk-table basketTable ");
			attr(div, "class", "uk-overflow-auto");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, table);
			append(table, thead);
			append(table, t9);
			append(table, tbody);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(tbody, null);
			}

			append(table, t10);
			append(table, tfoot);
			append(tfoot, tr1);
			append(tr1, td0);
			append(td0, t11);
			append(td0, t12);
			append(td0, t13);
			if (if_block) if_block.m(td0, null);
			append(tr1, t14);
			append(tr1, td1);
			append(tr1, t15);
			append(tr1, td2);
			append(td2, t16);
			append(td2, t17);
			append(tr1, t18);
			append(tr1, td3);
			append(tr1, t19);
			append(tr1, td4);
			append(td4, t20);
			append(td4, t21);
			append(tfoot, t22);
			append(tfoot, tr2);
			append(tr2, td5);
			append(td5, t23);
			append(td5, t24);
			append(tr2, t25);
			append(tr2, td6);
			append(tr2, t26);
			append(tr2, td7);
			append(td7, t27);
			append(td7, t28);
			append(tr2, t29);
			append(tr2, td8);
			append(tr2, t30);
			append(tr2, td9);
			append(td9, t31);
			append(td9, t32);
			append(tfoot, t33);
			append(tfoot, tr3);
			append(tr3, td10);
			append(tr3, t35);
			append(tr3, td11);
			append(tr3, t36);
			append(tr3, td12);
			append(tr3, t37);
			append(tr3, td13);
			append(tr3, t38);
			append(tr3, td14);
			append(td14, t39);
			append(td14, t40);
		},
		p(ctx, dirty) {
			if (dirty & /*$basketStore*/ 2) {
				each_value = /*$basketStore*/ ctx[1].items;
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(tbody, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			if (dirty & /*$orderStore*/ 4 && t12_value !== (t12_value = /*$orderStore*/ ctx[2].delivery_title + "")) set_data(t12, t12_value);

			if (/*$orderStore*/ ctx[2].delivery_data?.subtitleHTML) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_8(ctx);
					if_block.c();
					if_block.m(td0, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty & /*$orderStore*/ 4 && t16_value !== (t16_value = /*$orderStore*/ ctx[2].price_delivery.toLocaleString() + "")) set_data(t16, t16_value);
			if (dirty & /*$orderStore*/ 4 && t20_value !== (t20_value = /*$orderStore*/ ctx[2].price_delivery.toLocaleString() + "")) set_data(t20, t20_value);
			if (dirty & /*$orderStore*/ 4 && t24_value !== (t24_value = /*$orderStore*/ ctx[2].payment_title + "")) set_data(t24, t24_value);
			if (dirty & /*$orderStore*/ 4 && t27_value !== (t27_value = /*$orderStore*/ ctx[2].price_payment.toLocaleString() + "")) set_data(t27, t27_value);
			if (dirty & /*$orderStore*/ 4 && t31_value !== (t31_value = /*$orderStore*/ ctx[2].price_payment.toLocaleString() + "")) set_data(t31, t31_value);
			if (dirty & /*$basketPrice, $orderStore*/ 12 && t39_value !== (t39_value = (/*$basketPrice*/ ctx[3] + /*$orderStore*/ ctx[2].price_delivery + /*$orderStore*/ ctx[2].price_payment).toLocaleString() + "")) set_data(t39, t39_value);
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
			if (if_block) if_block.d();
		}
	};
}

// (33:24) {#if item.description}
function create_if_block_9(ctx) {
	let br;
	let t_value = /*item*/ ctx[4].description + "";
	let t;

	return {
		c() {
			br = element("br");
			t = text(t_value);
		},
		m(target, anchor) {
			insert(target, br, anchor);
			insert(target, t, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*$basketStore*/ 2 && t_value !== (t_value = /*item*/ ctx[4].description + "")) set_data(t, t_value);
		},
		d(detaching) {
			if (detaching) detach(br);
			if (detaching) detach(t);
		}
	};
}

// (29:12) {#each $basketStore.items as item}
function create_each_block(ctx) {
	let tr;
	let td0;
	let strong;
	let t0_value = /*item*/ ctx[4].title + "";
	let t0;
	let t1;
	let t2;
	let td1;

	let t3_value = (/*item*/ ctx[4].book_type == "PRINT"
	? "tištěná"
	: "ekniha") + "";

	let t3;
	let t4;
	let td2;
	let t5_value = /*item*/ ctx[4].unit_price.toLocaleString() + "";
	let t5;
	let t6;
	let t7;
	let td3;
	let t8_value = /*item*/ ctx[4].quantity + "";
	let t8;
	let t9;
	let td4;
	let t10_value = /*item*/ ctx[4].full_price.toLocaleString() + "";
	let t10;
	let t11;
	let t12;
	let if_block = /*item*/ ctx[4].description && create_if_block_9(ctx);

	return {
		c() {
			tr = element("tr");
			td0 = element("td");
			strong = element("strong");
			t0 = text(t0_value);
			t1 = space();
			if (if_block) if_block.c();
			t2 = space();
			td1 = element("td");
			t3 = text(t3_value);
			t4 = space();
			td2 = element("td");
			t5 = text(t5_value);
			t6 = text(" Kč");
			t7 = space();
			td3 = element("td");
			t8 = text(t8_value);
			t9 = space();
			td4 = element("td");
			t10 = text(t10_value);
			t11 = text(" Kč");
			t12 = space();
		},
		m(target, anchor) {
			insert(target, tr, anchor);
			append(tr, td0);
			append(td0, strong);
			append(strong, t0);
			append(td0, t1);
			if (if_block) if_block.m(td0, null);
			append(tr, t2);
			append(tr, td1);
			append(td1, t3);
			append(tr, t4);
			append(tr, td2);
			append(td2, t5);
			append(td2, t6);
			append(tr, t7);
			append(tr, td3);
			append(td3, t8);
			append(tr, t9);
			append(tr, td4);
			append(td4, t10);
			append(td4, t11);
			append(tr, t12);
		},
		p(ctx, dirty) {
			if (dirty & /*$basketStore*/ 2 && t0_value !== (t0_value = /*item*/ ctx[4].title + "")) set_data(t0, t0_value);

			if (/*item*/ ctx[4].description) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_9(ctx);
					if_block.c();
					if_block.m(td0, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty & /*$basketStore*/ 2 && t3_value !== (t3_value = (/*item*/ ctx[4].book_type == "PRINT"
			? "tištěná"
			: "ekniha") + "")) set_data(t3, t3_value);

			if (dirty & /*$basketStore*/ 2 && t5_value !== (t5_value = /*item*/ ctx[4].unit_price.toLocaleString() + "")) set_data(t5, t5_value);
			if (dirty & /*$basketStore*/ 2 && t8_value !== (t8_value = /*item*/ ctx[4].quantity + "")) set_data(t8, t8_value);
			if (dirty & /*$basketStore*/ 2 && t10_value !== (t10_value = /*item*/ ctx[4].full_price.toLocaleString() + "")) set_data(t10, t10_value);
		},
		d(detaching) {
			if (detaching) detach(tr);
			if (if_block) if_block.d();
		}
	};
}

// (53:20) {#if $orderStore.delivery_data?.subtitleHTML}
function create_if_block_8(ctx) {
	let div;
	let raw_value = /*$orderStore*/ ctx[2].delivery_data.subtitleHTML + "";

	return {
		c() {
			div = element("div");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			div.innerHTML = raw_value;
		},
		p(ctx, dirty) {
			if (dirty & /*$orderStore*/ 4 && raw_value !== (raw_value = /*$orderStore*/ ctx[2].delivery_data.subtitleHTML + "")) div.innerHTML = raw_value;;
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (94:8) {#if $orderStore.customer_type == 'PERSONAL'}
function create_if_block_6(ctx) {
	let t;

	return {
		c() {
			t = text("Soukromá osoba");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (97:8) {#if $orderStore.customer_type == 'COMPANY'}
function create_if_block_5(ctx) {
	let t;

	return {
		c() {
			t = text("Firma");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (103:13) {#if $orderStore.customer_type == 'COMPANY'}
function create_if_block_4(ctx) {
	let t0;
	let strong0;
	let t1_value = (/*$orderStore*/ ctx[2].company_name != undefined && /*$orderStore*/ ctx[2].company_name) + "";
	let t1;
	let t2;
	let br;
	let t3;
	let strong1;
	let t4_value = (/*$orderStore*/ ctx[2].company_id != undefined && /*$orderStore*/ ctx[2].company_id) + "";
	let t4;

	return {
		c() {
			t0 = text("Název společnosti ");
			strong0 = element("strong");
			t1 = text(t1_value);
			t2 = space();
			br = element("br");
			t3 = text("\n            IČ: ");
			strong1 = element("strong");
			t4 = text(t4_value);
		},
		m(target, anchor) {
			insert(target, t0, anchor);
			insert(target, strong0, anchor);
			append(strong0, t1);
			insert(target, t2, anchor);
			insert(target, br, anchor);
			insert(target, t3, anchor);
			insert(target, strong1, anchor);
			append(strong1, t4);
		},
		p(ctx, dirty) {
			if (dirty & /*$orderStore*/ 4 && t1_value !== (t1_value = (/*$orderStore*/ ctx[2].company_name != undefined && /*$orderStore*/ ctx[2].company_name) + "")) set_data(t1, t1_value);
			if (dirty & /*$orderStore*/ 4 && t4_value !== (t4_value = (/*$orderStore*/ ctx[2].company_id != undefined && /*$orderStore*/ ctx[2].company_id) + "")) set_data(t4, t4_value);
		},
		d(detaching) {
			if (detaching) detach(t0);
			if (detaching) detach(strong0);
			if (detaching) detach(t2);
			if (detaching) detach(br);
			if (detaching) detach(t3);
			if (detaching) detach(strong1);
		}
	};
}

// (122:12) {#if $orderStore.delivery_address_street}
function create_if_block_3(ctx) {
	let t0;
	let br0;
	let t1;
	let strong;
	let t2_value = /*$orderStore*/ ctx[2].delivery_address_street + "";
	let t2;
	let br1;

	return {
		c() {
			t0 = text("Doručovací adresa:");
			br0 = element("br");
			t1 = space();
			strong = element("strong");
			t2 = text(t2_value);
			br1 = element("br");
		},
		m(target, anchor) {
			insert(target, t0, anchor);
			insert(target, br0, anchor);
			insert(target, t1, anchor);
			insert(target, strong, anchor);
			append(strong, t2);
			insert(target, br1, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*$orderStore*/ 4 && t2_value !== (t2_value = /*$orderStore*/ ctx[2].delivery_address_street + "")) set_data(t2, t2_value);
		},
		d(detaching) {
			if (detaching) detach(t0);
			if (detaching) detach(br0);
			if (detaching) detach(t1);
			if (detaching) detach(strong);
			if (detaching) detach(br1);
		}
	};
}

// (127:12) {#if $orderStore.delivery_address_city}
function create_if_block_2(ctx) {
	let t0;
	let strong;
	let t1_value = /*$orderStore*/ ctx[2].delivery_address_city + "";
	let t1;
	let t2;

	return {
		c() {
			t0 = text("město: ");
			strong = element("strong");
			t1 = text(t1_value);
			t2 = text(",");
		},
		m(target, anchor) {
			insert(target, t0, anchor);
			insert(target, strong, anchor);
			append(strong, t1);
			insert(target, t2, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*$orderStore*/ 4 && t1_value !== (t1_value = /*$orderStore*/ ctx[2].delivery_address_city + "")) set_data(t1, t1_value);
		},
		d(detaching) {
			if (detaching) detach(t0);
			if (detaching) detach(strong);
			if (detaching) detach(t2);
		}
	};
}

// (131:12) {#if $orderStore.delivery_address_zip}
function create_if_block_1(ctx) {
	let t0_value = /*$orderStore*/ ctx[2].delivery_address_zip + "";
	let t0;
	let t1;
	let br;

	return {
		c() {
			t0 = text(t0_value);
			t1 = space();
			br = element("br");
		},
		m(target, anchor) {
			insert(target, t0, anchor);
			insert(target, t1, anchor);
			insert(target, br, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*$orderStore*/ 4 && t0_value !== (t0_value = /*$orderStore*/ ctx[2].delivery_address_zip + "")) set_data(t0, t0_value);
		},
		d(detaching) {
			if (detaching) detach(t0);
			if (detaching) detach(t1);
			if (detaching) detach(br);
		}
	};
}

// (136:12) {#if $orderStore.order_description}
function create_if_block(ctx) {
	let t0;
	let br;
	let t1_value = /*$orderStore*/ ctx[2].order_description + "";
	let t1;

	return {
		c() {
			t0 = text("Poznámka:");
			br = element("br");
			t1 = text(t1_value);
		},
		m(target, anchor) {
			insert(target, t0, anchor);
			insert(target, br, anchor);
			insert(target, t1, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*$orderStore*/ 4 && t1_value !== (t1_value = /*$orderStore*/ ctx[2].order_description + "")) set_data(t1, t1_value);
		},
		d(detaching) {
			if (detaching) detach(t0);
			if (detaching) detach(br);
			if (detaching) detach(t1);
		}
	};
}

function create_fragment(ctx) {
	let h1;
	let t1;
	let div0;
	let t3;
	let t4;
	let div5;
	let div1;
	let t5;
	let t6;
	let div4;
	let div2;
	let t7;
	let strong0;
	let t8_value = (/*$orderStore*/ ctx[2].name_and_surname != undefined && /*$orderStore*/ ctx[2].name_and_surname) + "";
	let t8;
	let t9;
	let br0;
	let t10;
	let strong1;
	let t11_value = (/*$orderStore*/ ctx[2].email != undefined && /*$orderStore*/ ctx[2].email) + "";
	let t11;
	let t12;
	let br1;
	let t13;
	let strong2;
	let t14_value = (/*$orderStore*/ ctx[2].phone != undefined && /*$orderStore*/ ctx[2].phone) + "";
	let t14;
	let t15;
	let div3;
	let t16;
	let strong3;
	let t17_value = (/*$orderStore*/ ctx[2].address_street != undefined && /*$orderStore*/ ctx[2].address_street) + "";
	let t17;
	let t18;
	let br2;
	let t19;
	let strong4;
	let t20_value = (/*$orderStore*/ ctx[2].address_city != undefined && /*$orderStore*/ ctx[2].address_city) + "";
	let t20;
	let t21;
	let strong5;
	let t22_value = (/*$orderStore*/ ctx[2].address_zip != undefined && /*$orderStore*/ ctx[2].address_zip) + "";
	let t22;
	let t23;
	let br3;
	let t24;
	let t25;
	let t26;
	let t27;
	let if_block0 = /*$basketItemsCount*/ ctx[0] > 0 && create_if_block_7(ctx);
	let if_block1 = /*$orderStore*/ ctx[2].customer_type == "PERSONAL" && create_if_block_6(ctx);
	let if_block2 = /*$orderStore*/ ctx[2].customer_type == "COMPANY" && create_if_block_5(ctx);
	let if_block3 = /*$orderStore*/ ctx[2].customer_type == "COMPANY" && create_if_block_4(ctx);
	let if_block4 = /*$orderStore*/ ctx[2].delivery_address_street && create_if_block_3(ctx);
	let if_block5 = /*$orderStore*/ ctx[2].delivery_address_city && create_if_block_2(ctx);
	let if_block6 = /*$orderStore*/ ctx[2].delivery_address_zip && create_if_block_1(ctx);
	let if_block7 = /*$orderStore*/ ctx[2].order_description && create_if_block(ctx);

	return {
		c() {
			h1 = element("h1");
			h1.textContent = "Shrnutí";
			t1 = space();
			div0 = element("div");
			div0.textContent = " ";
			t3 = space();
			if (if_block0) if_block0.c();
			t4 = space();
			div5 = element("div");
			div1 = element("div");
			if (if_block1) if_block1.c();
			t5 = space();
			if (if_block2) if_block2.c();
			t6 = space();
			div4 = element("div");
			div2 = element("div");
			if (if_block3) if_block3.c();
			t7 = text("\n\n            Jméno a příjmení: ");
			strong0 = element("strong");
			t8 = text(t8_value);
			t9 = space();
			br0 = element("br");
			t10 = text("\n            email ");
			strong1 = element("strong");
			t11 = text(t11_value);
			t12 = space();
			br1 = element("br");
			t13 = text("\n            tel.: ");
			strong2 = element("strong");
			t14 = text(t14_value);
			t15 = space();
			div3 = element("div");
			t16 = text("ulice: ");
			strong3 = element("strong");
			t17 = text(t17_value);
			t18 = space();
			br2 = element("br");
			t19 = text("\n            město: ");
			strong4 = element("strong");
			t20 = text(t20_value);
			t21 = text(",\n            ");
			strong5 = element("strong");
			t22 = text(t22_value);
			t23 = space();
			br3 = element("br");
			t24 = space();
			if (if_block4) if_block4.c();
			t25 = space();
			if (if_block5) if_block5.c();
			t26 = space();
			if (if_block6) if_block6.c();
			t27 = space();
			if (if_block7) if_block7.c();
			attr(div0, "class", "basketInfoEmpty");
			attr(div1, "class", "basketOrderPadd");
			attr(div2, "class", "basketOrderPerson");
			attr(div3, "class", "basketOrderPayment");
			attr(div4, "class", "basketOrder basketOrderPadd");
		},
		m(target, anchor) {
			insert(target, h1, anchor);
			insert(target, t1, anchor);
			insert(target, div0, anchor);
			insert(target, t3, anchor);
			if (if_block0) if_block0.m(target, anchor);
			insert(target, t4, anchor);
			insert(target, div5, anchor);
			append(div5, div1);
			if (if_block1) if_block1.m(div1, null);
			append(div1, t5);
			if (if_block2) if_block2.m(div1, null);
			append(div5, t6);
			append(div5, div4);
			append(div4, div2);
			if (if_block3) if_block3.m(div2, null);
			append(div2, t7);
			append(div2, strong0);
			append(strong0, t8);
			append(div2, t9);
			append(div2, br0);
			append(div2, t10);
			append(div2, strong1);
			append(strong1, t11);
			append(div2, t12);
			append(div2, br1);
			append(div2, t13);
			append(div2, strong2);
			append(strong2, t14);
			append(div4, t15);
			append(div4, div3);
			append(div3, t16);
			append(div3, strong3);
			append(strong3, t17);
			append(div3, t18);
			append(div3, br2);
			append(div3, t19);
			append(div3, strong4);
			append(strong4, t20);
			append(div3, t21);
			append(div3, strong5);
			append(strong5, t22);
			append(div3, t23);
			append(div3, br3);
			append(div3, t24);
			if (if_block4) if_block4.m(div3, null);
			append(div3, t25);
			if (if_block5) if_block5.m(div3, null);
			append(div3, t26);
			if (if_block6) if_block6.m(div3, null);
			append(div3, t27);
			if (if_block7) if_block7.m(div3, null);
		},
		p(ctx, [dirty]) {
			if (/*$basketItemsCount*/ ctx[0] > 0) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_7(ctx);
					if_block0.c();
					if_block0.m(t4.parentNode, t4);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (/*$orderStore*/ ctx[2].customer_type == "PERSONAL") {
				if (if_block1) {
					
				} else {
					if_block1 = create_if_block_6(ctx);
					if_block1.c();
					if_block1.m(div1, t5);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}

			if (/*$orderStore*/ ctx[2].customer_type == "COMPANY") {
				if (if_block2) {
					
				} else {
					if_block2 = create_if_block_5(ctx);
					if_block2.c();
					if_block2.m(div1, null);
				}
			} else if (if_block2) {
				if_block2.d(1);
				if_block2 = null;
			}

			if (/*$orderStore*/ ctx[2].customer_type == "COMPANY") {
				if (if_block3) {
					if_block3.p(ctx, dirty);
				} else {
					if_block3 = create_if_block_4(ctx);
					if_block3.c();
					if_block3.m(div2, t7);
				}
			} else if (if_block3) {
				if_block3.d(1);
				if_block3 = null;
			}

			if (dirty & /*$orderStore*/ 4 && t8_value !== (t8_value = (/*$orderStore*/ ctx[2].name_and_surname != undefined && /*$orderStore*/ ctx[2].name_and_surname) + "")) set_data(t8, t8_value);
			if (dirty & /*$orderStore*/ 4 && t11_value !== (t11_value = (/*$orderStore*/ ctx[2].email != undefined && /*$orderStore*/ ctx[2].email) + "")) set_data(t11, t11_value);
			if (dirty & /*$orderStore*/ 4 && t14_value !== (t14_value = (/*$orderStore*/ ctx[2].phone != undefined && /*$orderStore*/ ctx[2].phone) + "")) set_data(t14, t14_value);
			if (dirty & /*$orderStore*/ 4 && t17_value !== (t17_value = (/*$orderStore*/ ctx[2].address_street != undefined && /*$orderStore*/ ctx[2].address_street) + "")) set_data(t17, t17_value);
			if (dirty & /*$orderStore*/ 4 && t20_value !== (t20_value = (/*$orderStore*/ ctx[2].address_city != undefined && /*$orderStore*/ ctx[2].address_city) + "")) set_data(t20, t20_value);
			if (dirty & /*$orderStore*/ 4 && t22_value !== (t22_value = (/*$orderStore*/ ctx[2].address_zip != undefined && /*$orderStore*/ ctx[2].address_zip) + "")) set_data(t22, t22_value);

			if (/*$orderStore*/ ctx[2].delivery_address_street) {
				if (if_block4) {
					if_block4.p(ctx, dirty);
				} else {
					if_block4 = create_if_block_3(ctx);
					if_block4.c();
					if_block4.m(div3, t25);
				}
			} else if (if_block4) {
				if_block4.d(1);
				if_block4 = null;
			}

			if (/*$orderStore*/ ctx[2].delivery_address_city) {
				if (if_block5) {
					if_block5.p(ctx, dirty);
				} else {
					if_block5 = create_if_block_2(ctx);
					if_block5.c();
					if_block5.m(div3, t26);
				}
			} else if (if_block5) {
				if_block5.d(1);
				if_block5 = null;
			}

			if (/*$orderStore*/ ctx[2].delivery_address_zip) {
				if (if_block6) {
					if_block6.p(ctx, dirty);
				} else {
					if_block6 = create_if_block_1(ctx);
					if_block6.c();
					if_block6.m(div3, t27);
				}
			} else if (if_block6) {
				if_block6.d(1);
				if_block6 = null;
			}

			if (/*$orderStore*/ ctx[2].order_description) {
				if (if_block7) {
					if_block7.p(ctx, dirty);
				} else {
					if_block7 = create_if_block(ctx);
					if_block7.c();
					if_block7.m(div3, null);
				}
			} else if (if_block7) {
				if_block7.d(1);
				if_block7 = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(h1);
			if (detaching) detach(t1);
			if (detaching) detach(div0);
			if (detaching) detach(t3);
			if (if_block0) if_block0.d(detaching);
			if (detaching) detach(t4);
			if (detaching) detach(div5);
			if (if_block1) if_block1.d();
			if (if_block2) if_block2.d();
			if (if_block3) if_block3.d();
			if (if_block4) if_block4.d();
			if (if_block5) if_block5.d();
			if (if_block6) if_block6.d();
			if (if_block7) if_block7.d();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $basketItemsCount;
	let $basketStore;
	let $orderStore;
	let $basketPrice;
	component_subscribe($$self, basketItemsCount, $$value => $$invalidate(0, $basketItemsCount = $$value));
	component_subscribe($$self, basketStore, $$value => $$invalidate(1, $basketStore = $$value));
	component_subscribe($$self, orderStore, $$value => $$invalidate(2, $orderStore = $$value));
	component_subscribe($$self, basketPrice, $$value => $$invalidate(3, $basketPrice = $$value));
	return [$basketItemsCount, $basketStore, $orderStore, $basketPrice];
}

class Summary extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Summary;